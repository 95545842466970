import { useEffect } from "react";
import styled from "styled-components";
import { device } from "../../../style/styled-components/helpers/device";
import { Link } from "react-router-dom";
import ModalImage from "../../../resources/images/modal.jpeg";

const Modal = ({ onClose, isOpen, period: { from, to } }) => {
  const now = new Date();
  const isInDateRange = now >= from && now <= to;

  useEffect(() => {
    if (isOpen && isInDateRange) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
      `;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isOpen, isInDateRange]);

  if (!isOpen || !isInDateRange) return null;

  return (
    <ModalContainer onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>✕</CloseButton>
        <ModalImageStyled src={ModalImage} alt="모달 이미지" />
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;

// styled-components
const ModalContainer = styled.div`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000084;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px 5px #0008;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
  animation: fadeInUp 0.5s;
  background-color: white;
  width: 80%;

  @media ${device.mobileS} {
    width: 300px;
  }
  @media ${device.mobileM} {
    width: 350px;
  }
  @media ${device.mobileL} {
    width: 500px;
  }
  @media ${device.tablet} {
    width: 550px;
  }
`;

const ModalImageStyled = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 50%;
`;
