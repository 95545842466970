export const companycontext = {
  kr: {
    name: "관세법인 에이원",
    address1: "서울특별시 강남구 언주로 651 삼원빌딩(한국우편사업진흥원) 8층",
    address2: "(LX한국국토정보공사, 논현동)",
  },
  en: {
    name: "Aone Customs and Trade Service",
    address1: "",
    address2: "",
  },
};
